import React, { useEffect } from "react";
import ReactDOM from "react-dom";

import Traec from "traec";

/* CSS and other such imports for webpack to bundle up */
import "react-tippy/dist/tippy.css";

import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";

import WithAuthentication, { TokenRefreshOrRedirect } from "storybook-dashboard/auth";

import { ErrorBoundary } from "traec-react/errors";
import NavBar from "storybook-dashboard/navBar";
import store from "traec/redux/store";
import Home from "AppSrc/home";

import { AppRouter } from "./app-router";
import { getAzureConfig } from "./azure";
import DynamicStyle from "./styles";
import ReduxModals from "./utils/modal";
import UserProfile from "AppSrc/user";
import { NavBarBrand } from "./brand";
import { ModalProvider } from "storybook-dashboard/components/modal";
import { TermsAndConditionsRedirector, TermsAndConditions } from "storybook-dashboard/contracts/terms";
import { getAuthHost } from "storybook-dashboard/auth/utils";
import AccountsRouter from "storybook-dashboard/auth/router";
import { SupportDropdown } from "./support";
import CookieConsent from "react-cookie-consent";

/* CSS and other such imports for webpack to bundle up */
import "react-tippy/dist/tippy.css";

/*
NOTE: We use HashRouter here because the of issues raised here:
https://stackoverflow.com/questions/27928372/react-router-urls-dont-work-when-refreshing-or-writting-manually

With HashRouter approach then all urls sent from the server (as in emails etc.)
should use the site hostname follows to ensure that the hash is included 
before any URL extensions:
https://hostname/#/

It is also possible to use BrowserRouter, but write a catch-all on the
server to redirect all urls to index.html
*/

function AppLayout(props) {
  useEffect(() => {
    Traec.fetchRequiredFor({
      props,
      requiredFetches: [new Traec.Fetch("version", "read"), new Traec.Fetch("tenant_meta", "read")],
    });
  });

  return (
    <>
      <NavBar
        brand={<NavBarBrand />}
        preUserItems={<SupportDropdown />}
        include_myprofile={false}
        createText={""}
        azureConfig={getAzureConfig()}
      />
      <Switch>
        {/* UNRESTRICTED PAGES (no login required) */}
        <Route exact path="/" component={Home} />
        <Route exact path="/home" component={Home} />
        {/* Terms and conditions page */}
        <Route exact path="/terms" component={TermsAndConditions} />
        {/* All paths for accounts signup, login, password and sso*/}
        {/* Only the UserProfile page rendered at /accounts/profile is wrapped in TokenRefreshOrRedirect */}
        <Route path="/accounts" component={AccountsRouter} />

        {/* 
          All other pages require authentication. 
          TokenRefreshOrRedirect will try to refresh your token or redirect you to /accounts/login
          if refresh fails
        */}
        <Route
          render={() => (
            <TokenRefreshOrRedirect>
              <AppRouter />
            </TokenRefreshOrRedirect>
          )}
        />
      </Switch>
    </>
  );
}

ReactDOM.render(
  <ErrorBoundary title="Error loading the application">
    <Provider store={store}>
      <DynamicStyle />
      <ModalProvider>
        <BrowserRouter>
          <WithAuthentication
            host={getAuthHost()}
            hideMicrosoftSSO={false}
            UserProfile={UserProfile}
            postLoginPage="/accounts/profile"
            azureConfig={getAzureConfig()}
          >
            <TermsAndConditionsRedirector />
            {/* <CookieConsent
              contentStyle={{ margin: "10px 0px 10px 10px" }}
              disableStyles={true}
              style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}
              buttonClasses="btn btn-primary"
              containerClasses="alert alert-warning col-lg-12 mb-0"
              buttonText="OK"
              expires={1}
            >
              <p className="m-0">
                We are making some important updates which may impact your experience using the system at this time. Do
                not hesitate to{" "}
                <a
                  className="cookie-email"
                  style={{ color: "black" }}
                  href="mailto:info@actionsustainability.com?subject=Impacted by updates&body=Please describe your issue and when you experienced it."
                  target="_blank"
                >
                  contact us
                </a>{" "}
                so we can ensure there is no disruption to your business reporting as usual. Thank you for your
                continued support and patience.
              </p>
            </CookieConsent> */}
            <AppLayout />
          </WithAuthentication>
        </BrowserRouter>
      </ModalProvider>
      <ErrorBoundary>
        <ReduxModals />
      </ErrorBoundary>
    </Provider>
  </ErrorBoundary>,
  document.getElementById("app-root")
);
